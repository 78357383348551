<template>
  <div id="profile" class="row">
    <modal :required="true" :show.sync="removeUserModal" headerClasses="justify-content-center">
      <template slot="header">
        <h4> {{ $t('attentiontxt') }} </h4>
      </template>
      <div class="row">
        <div class="col-md-12" style="text-align: center">
          <p><b> {{ $t('sureRemoveUser') }}</b></p>
          <p> {{ $t('removeUserMessage') }}</p>
        </div>
      </div>
      <vue-hcaptcha
          v-if="showCaptcha"
          sitekey="7736ab28-5f0f-4eb9-810c-ca6f46e64b30"
          style="padding-left: 20%;"
          ref="recaptcha"
          @verify="verify"
      ></vue-hcaptcha>

      <template slot="footer">
        <div class="right-side">
          <p-button
              v-if="!showCaptcha"
              type="success"
              link
              v-on:click="confirmRemove">
            <span> {{ $t('confirm') }}</span>
          </p-button>
          <p-button
              v-if="showCaptcha"
              type="success"
              link
              :disabled="!captchaValid"
              v-on:click="removeUser">
            <span> {{ $t('confirm') }}</span>
          </p-button>
        </div>
        <div class="divider"></div>
        <div class="left-side">
          <p-button
              type="danger"
              link
              v-on:click="closeRemoveUserModal">
            <span> {{ $t('cancel') }}</span>
          </p-button>
        </div>

      </template>
    </modal>

    <div class="col-md-12">
      <div class="card card-user">
        <div class="card-header">
          <h5>
            <i class="fa fa-user"></i>
            {{ $t('updateprofile') }}
          </h5>
          <hr>
        </div>
        <div class="card-body">
          <div class="">
            <div class="row">
              <div class="col-md-3">

                <div class="author" style="margin-top: 40px;">

                  <div class="img-div text-center">
                    <vue-dropzone
                        id="userAvatar"
                        :options="dropOptions"
                        :class="!this.userAvatarUploaded && !this.dontUseAvatarImage && this.profileUpdated === 0 ? 'bg-danger' : ''"
                        @vdropzone-file-added="onUserAvatarAdded"
                        @vdropzone-complete="onUserAvatarUploaded"
                        @vdropzone-max-files-exceeded="onUploadRetry"
                        :ref="dropzoneUserAvatar"/>
                  </div>
                  <div class="icon-container">
                    <el-button v-if="showAvatarPlaceholder === 0 && !this.submitting" type="danger"
                               @click="removeUserAvatar"><i class="fa fa-trash"></i></el-button>
                  </div>
                  <label v-if="!this.userAvatarUploaded && !this.dontUseAvatarImage && this.profileUpdated === 0"
                         :class="!this.userAvatarUploaded ? 'text-danger' : ''">{{ $t('gravatar') }}</label>
                  <div v-if="!this.userAvatarUploaded"><br></div>
                  <p-checkbox v-if="!this.userAvatarUploaded && this.profileUpdated === 0" v-model="dontUseAvatarImage">
                    {{ $t('dont_update') }}
                  </p-checkbox>
                  <h5 class="title" style="margin-top:25px;">{{ fullname }}</h5>
                  <p class="description">
                    {{ user_info.email }}<i class="fa fa-pen"></i>
                  </p>
                </div>

              </div>
              <div class="col-md-6">

                <div class="col-md-12">
                  <div class="row">
                    <h5>
                      <i class="fa fa-user"></i>
                      {{ $t('userdetails') }}
                    </h5>
                  </div>
                </div>

                <div v-if="!submitting">
                  <fg-input
                      type="text"
                      :name="txt.nickname"
                      v-validate="modelValidations.nickname"
                      :error="getError(txt.nickname)"
                      v-model="user_data.nickname"
                      :label="txt.nickname"
                      required>
                  </fg-input>

                  <fg-input
                      type="text"
                      :name="txt.first_name"
                      v-validate="modelValidations.firstname"
                      :error="getError(txt.first_name)"
                      v-model="user_data.first_name"
                      :label="txt.first_name"
                      required>
                  </fg-input>

                  <fg-input
                      type="text"
                      :name="txt.last_name"
                      v-validate="modelValidations.lastname"
                      :error="getError(txt.last_name)"
                      v-model="user_data.last_name"
                      :label="txt.last_name"
                      required>
                  </fg-input>

                  <fg-input :name="txt.birth_date"
                            :error="getError(txt.birth_date)"
                            :label="txt.birth_date"
                            v-model="birth_date"
                            v-validate="modelValidations.birth_date"
                            required>

                    <DatePicker
                        :fieldName="txt.birth_date"
                        v-model="birth_date"
                        :placeholder="txt.birth_date"
                        @input="updateValidators"/>

                  </fg-input>

                  <fg-input
                      type="text"
                      :name="txt.phone"
                      v-validate="modelValidations.phone"
                      :error="getError(txt.phone)"
                      v-model="user_data.phone"
                      :label="txt.phone"
                      required>
                  </fg-input>


                  <div class="row">
                    <fg-input
                        type="text"
                        class="col-md-4"
                        :name="txt.city"
                        v-validate="modelValidations.city"
                        :error="getError(txt.city)"
                        v-model="user_data.city"
                        :label="txt.city"
                        required>
                    </fg-input>

                    <fg-input
                        type="text"
                        class="col-md-8"
                        :name="txt.address"
                        v-validate="modelValidations.address"
                        :error="getError(txt.address)"
                        v-model="user_data.address"
                        :label="txt.address"
                        required>
                    </fg-input>
                  </div>

                  <div class="row">
                    <div class="col-md-6 col-sm-12">
                      <fg-input
                          :label="txt.zip"
                          :name="txt.zip"
                          v-validate="modelValidations.zip"
                          :error="getError(txt.zip)"
                          v-model="user_data.zip_code"
                          type="text">
                      </fg-input>
                    </div>
                    <div class="col-md-6 col-sm-12">
                      <label>
                        {{ $t('country') }}
                      </label>
                      <select
                          :name="txt.country"
                          v-validate="modelValidations.country"
                          :error="getError(txt.country)"
                          v-model="user_data.country"
                          class="form-control">
                        <option
                            v-for="option in countries"
                            :value="option.code">
                          {{ option.name }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label>{{ txt.aboutlabel }}</label>
                        <textarea
                            rows="5"
                            class="form-control border-input"
                            v-model="user_data.bio"
                            :placeholder="txt.aboutmeph">
                      </textarea>
                      </div>
                    </div>
                  </div>
                </div>

                <div v-if="submitting" class="text-center">
                  <h2>{{ $t('submitting') }}</h2>
                  <i class="fa fa-cog fa-spin fa-huge fa-fw" style="color: #51bcda;"></i>
                </div>

              </div>
              <div class="col-md-3">

                <div class="col-md-12">
                  <div class="row">
                    <h5>
                      <i class="fa fa-gear"></i>
                      {{ $t('settings') }}
                    </h5>
                  </div>
                </div>
                <div class="col-md-12">
                </div>
                <div class="col-md-12">
                  <div class="row">
                    <label>
                      {{ $t('user_lang') }}
                    </label>
                  </div>
                  <div class="row">
                    <el-select
                        class="select col-md-12 row"
                        v-model="user_data.lang"
                        size="large">
                      <el-option
                          v-for="option in languages"
                          class="select"
                          :value="option.code"
                          :label="option.name"
                          :key="option.code">
                       <span style="margin-right: 10px;">
                         <img :src="`/static/img/flags/${getLocale(option.code)}.png`">
                       </span>
                        <span>
                         {{ option.name }}
                       </span>
                      </el-option>
                    </el-select>
                  </div>
                </div>

                <div class="col-md-12 mt-4">
                  <div class="row">
                    <h5>
                      <i class="fa fa-pencil-square-o"></i>
                      {{ $t('subscriptions') }}
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            <div class="row margin-adjust">
              <div class="col-md-12">
                <p-button
                    v-if="!submitting"
                    slot="footer"
                    @click="validate()"
                    type="primary">
                  <i class="fa fa-floppy-o"></i>
                  {{ $t('updateprofile') }}
                </p-button>
                <p-button
                    v-if="!submitting"
                    @click="resetpass()"
                    type="danger">
                  <i class="fa fa-refresh"></i>
                  {{ $t('resetpassword') }}
                </p-button>
                <p-button style="float: right;"
                          v-if="!submitting"
                          @click="deleteAccount()"
                          type="danger">
                  <i class="fa fa-trash"></i>
                  {{ $t('delacctitle') }}
                </p-button>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer">
        </div>
      </div>
    </div>
    <passwordreset
        :userid="user_info.email"
        ref="resetpass">
    </passwordreset>
  </div>
</template>

<script>
import Vue from 'vue'
import UserCard from "@/components/Dashboard/Views/Dashboard/UserProfile/UserCard";
import passwordreset from "@/components/Dashboard/Views/Dashboard/UserProfile/passwordreset";
import {Modal} from 'src/components/UIComponents'
import vueDropzone from "vue2-dropzone";
import {Upload, Button} from 'element-ui'
import swal from "sweetalert2";
import CONSTANTS from "@/assets/constants";
import VueCookies from "vue-cookies";
import {MD5} from 'crypto-js';
import DatePicker from "src/components/UIComponents/Inputs/DatePicker";
import VueHcaptcha from "@hcaptcha/vue-hcaptcha";

const user_data = JSON.parse(localStorage.getItem('user-info'))

const logo_base_url = "https://hoko.ams3.digitaloceanspaces.com/"

const upload_url = CONSTANTS.methods.mainapiurlV4() + "/upuseravatar/"

const auth_headers = {
  'Authorization': 'Bearer ' + VueCookies.get('dash-at'),
  'idtoken': VueCookies.get('dash-it')
}

Vue.use(Upload)
Vue.use(Button)

export default {
  name: "Profile",
  components: {
    passwordreset,
    vueDropzone,
    DatePicker,
    UserCard,
    Modal,
    Vue,
    VueHcaptcha
  },
  data() {
    return {
      submitting: false,
      user_info: user_data,
      user_data: user_data.user_metadata,
      fullname: user_data.user_metadata.first_name + " " + user_data.user_metadata.last_name,
      birth_date_temp: '',
      birth_date: '',
      userAvatarUploaded: false,
      dontUseAvatarImage: false,
      profileUpdated: 0,
      dropzoneUserAvatar: 'dropzoneUserAvatar',
      showAvatarPlaceholder: -1,
      dropOptions: {
        headers: auth_headers,
        url: upload_url,
        maxFilesize: 1,
        maxFiles: 1,
        createImageThumbnails: false,
        addRemoveLinks: false,
        autoProcessQueue: true,
        acceptedFiles: "image/png,image/jpg,image/jpeg,image/gif",
        dictDefaultMessage: "<i class='nc-icon nc-cloud-upload-94' style='font-size: 35px;'></i>" + this.$t('uploadtxt'),
      },
      txt: {
        nickname: this.$t('nickname'),
        first_name: this.$t('firstname'),
        last_name: this.$t('lastname'),
        city: this.$t('city'),
        address: this.$t('address'),
        zip: this.$t('zip'),
        aboutlabel: this.$t('aboutme'),
        aboutmeph: this.$t('aboutmedescription'),
        country: this.$t('country'),
        birth_date: this.$t('birthdatetxt'),
        phone: this.$t('phonetxt'),
      },
      countries: this.$country_list(),
      whitelabel_settings: {},
      languages: [
        {
          'code': 'en',
          'name': 'English'
        },
        {
          'code': 'pt',
          'name': 'Portuguese'
        },
        {
          'code': 'it',
          'name': 'Italian'
        },
        {
          'code': 'ar',
          'name': 'Arabic'
        },
        {
          'code': 'es',
          'name': 'Spanish'
        },
        {
          'code': 'zh',
          'name': 'Chinesse'
        },
        {
          'code': 'zhs',
          'name': 'Chinesse (Simplified)'
        },
        {
          'code': 'de',
          'name': 'Germany'
        },
        {
          'code': 'fr',
          'name': 'French'
        }, {
          'code': 'ru',
          'name': 'Russian'
        },
        {
          'code': 'vi',
          'name': 'Vietnam'
        },
        {
          'code': 'ro',
          'name': 'Romanian'
        }
      ],
      modelValidations: {
        nickname: {required: true},
        firstname: {required: true},
        lastname: {required: true},
        birth_date: {required: true},
        address: {required: true},
        city: {required: true},
        zip: {required: true},
        country: {required: true},
        email: {required: true},
        phone: {required: true},
      },
      lang: {
        formatLocale: {
          monthsShort: [
            this.$t('JanShort'),
            this.$t('FevShort'),
            this.$t('MarShort'),
            this.$t('AprShort'),
            this.$t('MayShort'),
            this.$t('JunShort'),
            this.$t('JulShort'),
            this.$t('AugShort'),
            this.$t('SepShort'),
            this.$t('OctShort'),
            this.$t('NovShort'),
            this.$t('DecShort'),],
          weekdaysMin: [
            this.$t('SundayShort'),
            this.$t('MondayShort'),
            this.$t('TuesdayShort'),
            this.$t('WednesdayShort'),
            this.$t('ThursdayShort'),
            this.$t('FridayShort'),
            this.$t('SaturdayShort'),
          ],
        },
      },
      removeUserModal: false,
      showCaptcha: false,
      captcha_token: true,
      captchaValid: false,
      previous_lang: localStorage.getItem('user-lang'),
    }
  },
  methods: {
    updateValidators() {
      this.$nextTick(() => {
        this.$validator.validate(this.txt.nickname);
        this.$validator.validate(this.txt.first_name);
        this.$validator.validate(this.txt.last_name);
        this.$validator.validate(this.txt.birth_date);
        this.$validator.validate(this.txt.address);
        this.$validator.validate(this.txt.city);
        this.$validator.validate(this.txt.phone);
      })
    },
    getLocale(locale) {
      if (locale === 'en') {
        return 'GB';
      }
      if (locale === 'ar') {
        return 'AE';
      }
      if (locale === 'zhs') {
        return 'ZH';
      }
      return locale.toUpperCase()
    },
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    resetpass() {
      this.$refs.resetpass.showModal();
    },
    deleteAccount() {
      this.removeUserModal = true;
    },
    confirmRemove() {
      this.showCaptcha = true;
    },
    removeUser() {
      const ticket_created = resp => {
        swal(this.$t('successtxt'), this.$t('ticketcreated'), 'success').then(this.closeRemoveUserModal)
      }

      const ticket_failed = resp => {
        swal(this.$t('attentiontxt'), this.$t('somethingwentwrong'), "warning").then(this.closeRemoveUserModal)
      }

      const ticket = {
        "userid": user_data['email'],
        "name": user_data['user_metadata']['first_name'] + " " + user_data['user_metadata']['last_name'],
        "subject": "DELETE ACCOUNT",
        "type": "Support",
        "text64": btoa("Dear HokoTeam,\n" +
            "\n" +
            "This user has requested the deletion of his client profile directly from his profile page.\n" +
            "\n" +
            "Best Regards"),
        "captcha_token": this.captcha_token,
      }

      this.$createTicket(ticket).then(ticket_created, ticket_failed);
    },
    closeRemoveUserModal() {
      this.showCaptcha = false
      // this.captcha_token = null
      this.captchaValid = false
      this.removeUserModal = false
    },
    verify(resp) {
      this.captcha_token = resp
      this.captchaValid = true
    },
    refreshPage() {
      this.auth.logout();
    },
    removeUserAvatar() {
      swal({
        title: this.$t("confirm"),
        text: this.$t("confirm_remover_avatar"),
        type: 'warning',
        showCancelButton: true,
      }).then(this.delUserAvatar)
    },
    delUserAvatar() {
      this.profileUpdated = 1
      this.showAvatarPlaceholder = 1
      this.submit(true)
    },
    async validate() {
      if (this.user_data.nickname && this.user_data.nickname.length) {
        const nicknameExists = await this.$checkIfNicknameExists(this.user_data.nickname);
        if (nicknameExists.success) {
          this.$toast.warning('nickname_exists');
          return;
        }
      }
      const isValidForm = await this.$validator.validateAll();
      this.submit(isValidForm);
    },
    submit(isValid) {
      if (isValid) {

        this.submitting = true;

        const json_data = {
          "address": this.user_data.address,
          "city": this.user_data.city,
          "state": this.user_data.state,
          "zip_code": this.user_data.zip_code,
          "country": this.user_data.country,
          "bio": this.user_data.bio,
          "nickname": this.user_data.nickname,
          "first_name": this.user_data.first_name,
          "last_name": this.user_data.last_name,
          "phone": this.user_data.phone,
          "birth_date": this.birth_date.replaceAll("-", "."),
          "lang": this.user_data.lang,
          "eml": this.user_info.email,
          "oldLang": this.previous_lang,
          "profile_updated": 1,
          "show_avatar_placeholder": this.showAvatarPlaceholder,
          "picture": this.user_info.picture,
        };

        this.$updateUserInfo(this.user_info.user_id, json_data)
            .then(resp => this.successHandler(resp, json_data), this.errorHandler)
      }
    },
    successHandler(resp, json_data) {
      this.submitting = false;
      if (!resp.success) {
        this.$toast.warning(this.$t(resp.msg));
        return;
      }
      this.$toast.success(this.$t(resp.msg));
      user_data.user_metadata = json_data;
      localStorage.setItem('user-info', JSON.stringify(user_data));
      location.reload()
    },
    errorHandler(resp) {
      this.submitting = false;
      if (resp.data && resp.data.msg) {
        this.$toast.error(this.$t(resp.data.msg));
      }
    },
    mountUserAvatarImage() {

      let picture = this.user_info.picture

      if (picture) {
        this.$nextTick(() => {
          const container = this.$refs[this.dropzoneUserAvatar].$el.querySelector('.dz-default.dz-message');
          const img = document.createElement('img');

          if (this.showAvatarPlaceholder) {

            const img_path = "/static/img/user_placeholder.png"
            this.user_info.picture = "https://dash.hokocloud.com" + img_path
            img.src = this.user_info.picture
          } else {
            img.src = picture
          }

          container.removeChild(container.firstChild);
          container.appendChild(img);
        });
      }
    },
    onUserAvatarAdded(file) {

      if (file.status === "error") {
        this.userAvatarUploaded = false
        this.$toast.clear()
        this.$toast.warning(this.$t("error_upload_avatar"));
        return;
      }

      this.submitting = true

      let filename = `${this.user_data.last_name}_${this.user_data.first_name}`
      filename = filename.replace(/\s/g, '')
          .replace('.', '')
          .toLowerCase()
      const url = upload_url + filename
      this.userAvatarUploaded = true

      this.$refs[this.dropzoneUserAvatar].setOption('url', url)
    },
    onUserAvatarUploaded(file) {

      if (file.status === 'error') {
        this.userAvatarUploaded = false
        this.$toast.clear()
        this.$toast.warning(this.$t('ERROR_UNEXPECTED'));
        return;
      }
      const response = JSON.parse(file.xhr.response)
      let picture = response.data.logo_name

      this.user_info.picture = this.getLogoUrl(picture)
      this.userAvatarUploaded = true
      this.showAvatarPlaceholder = 0

      file.previewElement.querySelector('.dz-image img').src = `${this.getLogoUrl(picture)}`

      let container = this.$refs[this.dropzoneUserAvatar].$el.querySelector('.dz-default.dz-message')
      container.style.display = 'none'

      this.sleep(2200)

      //force update
      this.validate()
    },
    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    onUploadRetry(file, dropzone) {
      const dropzoneElement = this.$refs[dropzone];
      dropzoneElement.removeAllFiles();
      dropzoneElement.addFile(file);
    },
    getLogoUrl(logoName) {
      return `${logo_base_url}${logoName}`
    },
    setupBirthDate() {
      this.birth_date = this.user_data.birth_date.replaceAll(".", "-")
    },
    initComponents(resp) {
      this.profileUpdated = resp.data[0].profile_updated
      this.showAvatarPlaceholder = resp.data[0].show_avatar_placeholder
      this.updateValidators()
      this.setupBirthDate()
      this.mountUserAvatarImage()
    },
    loadUserInfo() {
      this.$getUserInfo(user_data.email).then(this.initComponents, function (err) {
        console.log(err);
      });
    },
  },
  mounted() {
    this.loadUserInfo()
  },
}
</script>

<style>

.img-div::v-deep {
  .vue-dropzone.dropzone:hover {
    .dz-image, .dz-details {
      color: transparent !important;
      background: transparent !important;
      border: none !important;
      transition: .2s linear;

      img {
        webkit-transform: none;
        -moz-transform: none;
        -ms-transform: none;
        -o-transform: none;
        transform: none;
        -webkit-filter: none;
        filter: none;
      }
    }
  }

  .vue-dropzone.dropzone {
    .dz-image, .dz-details {
      background-color: transparent !important;
      border: none;
      transition: .2s linear;

      &:hover {
        color: transparent;
        background-color: transparent;
        border: none !important;
        transition: .2s linear;
      }
    }

    .dz-preview.dz-file-preview.dz-complete .dz-image {
      opacity: 1 !important;
      border-radius: 0;
      background: inherit;
    }

    .dz-complete {
      .dz-details {
        opacity: 0 !important;
      }
    }
  }
}

.mx-datepicker {
  position: relative;
  display: inline-block;
  width: 100%;

}

.icon-container {
  position: relative;
  margin-top: -83px;
  margin-right: 23px;
  float: right;
  z-index: 99999;
}

.margin-adjust {
  margin-left: 10px;
  margin-right: 10px;
}

.bg-danger {
  background-color: #FFC0A4 !important;
  border: 1px solid #ef8157 !important;
  color: #ef8157 !important;
}

.el-button--danger {
  color: #FFF;
  background-color: #ef8157;
  border-color: #ef8157;
}

</style>

