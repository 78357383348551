<template>
  <div>
    <modal :required="true" :show="modalreset">
      <template slot="header">
        <h5>
          {{ $t('resetpassword') }}
        </h5>
      </template>

      <template slot="default" v-if="!submitting">
        <div class="row">
          <div class="col-md-12">
            <span>
              {{ $t('inforesetpassword') }}
            </span>
          </div>
        </div>
        <div class="row text-center">
          <div class="col-md-12" style="margin-top: 20px">
            <vue-hcaptcha
              v-if="modalreset && !submitting"
              sitekey="7736ab28-5f0f-4eb9-810c-ca6f46e64b30"
              ref="recaptcha"
              @verify="verify"
            ></vue-hcaptcha>
          </div>
        </div>
      </template>

      <template slot="default" v-if="submitting">
        <div class="text-center">
          <h2>{{ $t('submitting') }}</h2>
          <i class="fa fa-cog fa-spin fa-huge fa-fw" style="color: #51bcda;"></i>
        </div>
      </template>

      <template slot="footer" v-if="!submitting">
        <div class="left-side">
          <p-button type="success" @click="submit()" link>
            {{ $t('confirm') }}
          </p-button>
        </div>
        <div class="divider"></div>
        <div class="right-side">
          <p-button type="danger" @click="modalreset = false;" link>
            {{ $t('cancel') }}
          </p-button>
        </div>
      </template>

      <template slot="footer" v-if="submitting">
        <div class="left-side">
          <p-button type="default" link>
            {{ $t('confirm') }}
          </p-button>
        </div>
        <div class="divider"></div>
        <div class="right-side">
          <p-button type="default" link>
            {{ $t('cancel') }}
          </p-button>
        </div>
      </template>

    </modal>
  </div>
</template>

<script>
  import { Modal } from 'src/components/UIComponents'
  import VueHcaptcha from "@hcaptcha/vue-hcaptcha";
  export default {
    name: "passwordreset",
    props: {
      userid: {
        type: String,
        description: 'User Id'
      }
    },
    components: {
      Modal,
      VueHcaptcha,
    },
    data() {
      return {
        submitting: false,
        modalreset: false,
        captcha_token: null,
      };
    },
    methods: {
      verify(resp) {
        this.captcha_token = resp;
      },
      showModal() {
        this.captcha_token = null
        this.modalreset = true
      },
      submit() {
        this.submitting = true;
        this.$sendreset({
          userid: this.userid,
          captcha_token: this.captcha_token
        }).then(this.successResetHandler, this.errorHandler)
      },
      successResetHandler(resp) {
        if (!resp.success) {
          this.errorHandler(resp)
          return
        }
        this.submitting = false;
        this.modalreset = false;
        this.$toast.success(this.$t('emailresetsent'));
      },
      errorHandler(resp) {
        if (resp.data && resp.data.msg) {
          this.$toast.error(this.$t(resp.data.msg));
        }
        this.submitting = false;
      }
    },
    mounted() {
    }
  }
</script>

<style scoped>

</style>
